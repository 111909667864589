import { storeConfigVar } from '@root/core/services/graphql/cache';

const pwaConfig = storeConfigVar();

export const PRIMARY = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.primary_color ? pwaConfig.pwa.primary_color : '#000000';
export const SECONDARY = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.secondary_color ? pwaConfig.pwa.secondary_color : '#818181';

export const FONT_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.font_color ? pwaConfig.pwa.font_color : '#000000';
export const BACKGROUND_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.background_color ? pwaConfig.pwa.background_color : '#FFFFFF';

export const BADGE_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.button_background_color ? pwaConfig.pwa.button_background_color : '#000000';

export const TEXT_TOP_MENU = '#374151';
export const GRAY_PRIMARY_LIGHT = '#DEDEDE';
export const GRAY_PRIMARY = '#282828';
export const GRAY_SECONDARY = '#4B5563 ';
export const GRAY_SECONDARY_LIGHT = '#B4B4B4';
export const GRAY_THIRD = '#FAFAFA';
export const GRAY_LIGHT = '#F3F4F6';
export const WHITE = '#FFFFFF';
export const WHITE_IMPORTANT = '#FFFFFF !important';

export const ERROR_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.error_color ? pwaConfig.pwa.error_color : '#FF0000';
export const WARNING_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.warning_msg_color ? pwaConfig.pwa.warning_msg_color : '#FE5D26';
export const SUCCESS_COLOR = pwaConfig && pwaConfig.pwa && pwaConfig.pwa.success_msg_color ? pwaConfig.pwa.success_msg_color : '#46954D';
export const RED = '#FF0000';
export const ORANGE = '#FE5D26';
export const GREEN = '#46954D';
export const BLACK = '#000000';
export const THERMOS_RED = '#D71518';
export const THERMOS_RED_HOVER = '#D71518';
